<template>
  <form>
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="form-group">
      <label for="paynow_payment_option">Payment Option</label>
      <select
        class="form-control"
        id="paynow_payment_option"
        v-model="paynowOption"
      >
        <option value="ecocash">Ecocash</option>
        <option value="onemoney">OneMoney</option>
        <option value="zimswitch">Zimswitch</option>
        <option value="telecash">Telecash</option>
      </select>
      <small v-if="errors && errors.method" class="text-danger">
        {{ errors.method[0] }}
      </small>
    </div>
    <div class="form-group">
      <div class="form-group__content">
        <label>Phone Number</label>
        <input
          type="text"
          name="Name"
          placeholder="+263772440088"
          v-model="phone_number"
        />
        <small v-if="errors && errors.phone_number" class="text-danger">
          {{ errors.phone_number[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group__content">
        <label>Email</label>
        <input
          type="text"
          name="Name"
          placeholder="john@example.com"
          v-model="email"
        />
        <small v-if="errors && errors.email" class="text-danger">
          {{ errors.email[0] }}
        </small>
      </div>
    </div>
    <!--    <button class="ps-btn ps-btn&#45;&#45;fullwidth" v-if="waiting">-->
    <!--      {{buttonText }}-->
    <!--      <span class="spinner-border" role="status">-->
    <!--        <span class="sr-only">Loading...</span>-->
    <!--      </span>-->
    <!--    </button>-->
    <button class="btn btn-primary ls-button" @click.prevent="processPayment()">
      <span v-if="loading" class="spinner-border" role="status">
        <span class="sr-only">Processing Payment...</span>
      </span>
      <span v-else>
        Process Payment
      </span>
    </button>
  </form>
</template>

<script>
import axios from "axios";

export default {
  name: "PayNow",
  props: {
    order: Object,
  },
  data() {
    return {
      phone_number: null,
      email: null,
      defaultOption: "ecocash",
      message: null,
      paynowOption: "ecocash",
      loading: false,
      waiting: false,
      buttonText: "Waiting...",
      isError: false,
      errors: null,
    };
  },
  methods: {
    processPayment() {
      this.loading = true;
      const data = {
        phone_number: this.phone_number,
        email: this.email,
        method: this.paynowOption,
      };
      axios
        .post("paynow/" + this.order.id, data)
        .then((response) => {
          if (response.data.message) {
            this.message = response.data.message;
          }
          this.loading = false;
          this.$emit("payment-result", { isPaid: true });
          this.$store.dispatch("cart/clearCart");
        })
        .catch((error) => {
          if (error.response.status === 422) {
            if (error.response.data.message) {
              this.message = error.response.data.message;
            } else {
              this.message =
                "Payment could not be completed. Please enter all valid required fields.";
            }
            this.errors = error.response.data.errors;
          } else {
            this.message = error.backendErrors[0];
          }
          this.isError = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.ps-block__content {
  padding: 30px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 2px solid #eaeaea;
}

.container-fluid {
  background-color: #c5cae9;
}

.heading {
  font-size: 40px;
  margin-top: 35px;
  margin-bottom: 30px;
  padding-left: 20px;
}

.card {
  border-radius: 10px !important;
  margin-top: 60px;
  margin-bottom: 60px;
}

.form-card {
  margin-left: 20px;
  margin-right: 20px;
}

.form-card input,
.form-card textarea {
  padding: 10px 15px 5px 15px;
  border: none;
  border: 1px solid lightgrey;
  border-radius: 6px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: arial;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

.form-card input:focus,
.form-card textarea:focus {
  -moz-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  -webkit-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  box-shadow: 0px 0px 0px 1.5px skyblue !important;
  font-weight: bold;
  border: 1px solid #304ffe;
  outline-width: 0;
}

.input-group {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.input-group input {
  position: relative;
  height: 80px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 6px;
  padding-top: 30px;
  padding-left: 25px;
}

.input-group label {
  position: absolute;
  height: 24px;
  background: none;
  border-radius: 6px;
  line-height: 48px;
  font-size: 15px;
  color: gray;
  width: 100%;
  font-weight: 100;
  padding-left: 25px;
}

input:focus + label {
  color: #304ffe;
}

.btn-pay {
  background-color: #304ffe;
  height: 60px;
  color: #ffffff !important;
  font-weight: bold;
}

.btn-pay:hover {
  background-color: #3f51b5;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

img {
  border-radius: 5px;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  border-radius: 6px;
  box-sizing: border-box;
  border: 2px solid lightgrey;
  cursor: pointer;
  margin: 12px 25px 12px 0px;
}

.radio:hover {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}

.radio.selected {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 155, 0.4);
  border: 2px solid blue;
}

.label-radio {
  font-weight: bold;
  color: #000000;
}
</style>
