<template>
    <div>Master Card</div>
</template>

<script>
export default {
  name: "MasterCard",
  props: {
    order: Object
  }
}
</script>

<style scoped>

</style>