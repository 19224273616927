<template>
  <div class="row">
    <div class="col-12 justify-content-center">
      <div v-if="errors.message" class="alert alert-danger" role="alert">
        {{ errors.message }}
      </div>
      <div class="text-h4 mb-5">Please give us your payment details:</div>

      <StripeElementCard class="stripe-card" ref="elementRef" :pk="publishableKey" :hidePostalCode="true"
        @token="tokenCreated" @change="errorHandler($event)"></StripeElementCard>

      <div v-if="errorMessage" class="mt-3 text-danger" id="card-errors" role="alert" v-text="errorMessage"></div>
      <button class="ps-btn ps-btn--fullwidth mt-5" v-on:click.prevent.default="onSubmit">
        <span v-if="isLoading">Processing Payment...</span>
        <span v-else>Make Payment</span>
      </button>
    </div>
  </div>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import axios from "axios";

export default {
  name: "Stripe",
  components: { StripeElementCard },
  props: ["order"],
  data() {
    return {
      errors: {
        message: "",
      },
      errorMessage: "",
      complete: false,
      stripeOptions: {},
      isLoading: false,
      token: null,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    };
  },
  methods: {
    errorHandler(event) {
      this.errorMessage = event.error ? event.error.message : "";
    },
    onSubmit() {
      // console.log("key", process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      this.isLoading = true;
      axios
        .post(`stripe/${this.order.id}`, token)
        .then((response) => {
          this.isLoading = false;
          this.paid = true;
          this.$emit("payment-result", { isPaid: true });
          this.$store.dispatch("cart/clearCart");
        })
        .catch((error) => {
          if (error) {
            if (error.response && error.response.status === 422) {
              if (error.response.data.message) {
                this.errors.message = error.response.data.message;
              } else {
                this.errors.message =
                  "Payment could not be completed, please try again.";
              }
            } else if (error.response && error.response.status === 401) {
              this.errors.message = error.response.data.message;
            } else if (error.backendErrors) {
              this.errors.message = error.backendErrors[0];
            }
          } else {
            this.errors.message = "Unexpected error occurred.";
          }
          this.isError = true;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
#card-errors {
  color: #fa755a;
}

.StripeElement {
  box-sizing: border-box;
  height: 45px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripe-element-errors {
  color: #fa755a;
  margin-top: 3px !important;
}
</style>
