<template>
  <div class="ps-page--simple">
    <div class="ps-breadcrumb">
      <div class="container">
        <ul class="breadcrumb">
          <li>
            <router-link :to="{ name: 'home' }">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'shop_products' }">Shop</router-link>
          </li>
          <li><a href>Checkout</a></li>
          <li>Payment</li>
        </ul>
      </div>
    </div>
    <div class="ps-block--payment">
      <div v-if="isPaid" class="container">
        <div class="ps-block--payment-success">
          <h3>{{ success_message }} !</h3>
          <div v-if="message" class="alert alert-secondary" role="alert">
            {{ message }}
          </div>
          <p>
            Thank your for paying for your order. Please visit
            <router-link :to="{
              name: 'my_order',
              params: { id: $route.params.id },
            }">here</router-link>
            to check your order details.
          </p>
        </div>
      </div>
      <div v-else class="container">
        <h1 style="margin-bottom: 50px" class="ml-0 pl-0">Shopping Cart</h1>
        <div class="ps-section__content">
          <form class="ps-form--checkout">
            <div class="row">
              <div class="col-xl-7 col-lg-8 col-md-12 col-sm-12 ps-block__content">
                <form class="form-card">
                  <div class="ps-block--shipping"></div>
                  <div v-if="paymentMethods && paymentMethods.length" class="border-1">
                    <h3 class="ls-center">Please Choose Payment Method</h3>
                    <PaymentOptionsTab v-if="paymentMethods.length > 0" :payment-methods="paymentMethods"
                      :selected-method="selectedMethod" @open-payment-method="changeSelectedMethod"></PaymentOptionsTab>
                    <br />
                    <div class="row justify-content-center form-group">
                      <div class="col-12 px-auto"></div>
                    </div>
                    <div class="mb-5 mx-0 p-0" style="display: none">
                      <div class="ls-paymentmethod">
                        <h3 class="ls-center">Pay with Pahukama Voucher</h3>
                        <Voucher :order="order" @payment-result="handlePaymentResult"></Voucher>
                      </div>
                    </div>
                    <div class="mb-5 mx-0 p-0" v-if="paymentMethods.length > 0" v-for="paymentMethod in paymentMethods">
                      <div class="ls-paymentmethod" v-if="paymentMethod.slug === 'pay-now' &&
              selectedMethod === paymentMethod.slug
              ">
                        <h3 class="ls-center">Pay with PayNow</h3>
                        <PayNow :order="order" @payment-result="handlePaymentResult"></PayNow>
                      </div>
                      <div class="ls-paymentmethod" v-if="paymentMethod.slug === 'paypal' &&
              selectedMethod === paymentMethod.slug">
                        <h3 class="ls-center">Pay with PayPal</h3>
                        <PayPal :order="order" @payment-result="handlePaymentResult"></PayPal>
                        <div v-if="isPaid">
                          <p>Payment Successful!</p>
                        </div>
                      </div>
                      <div class="ls-paymentmethod" v-if="paymentMethod.slug === 'stripe' &&
              selectedMethod === paymentMethod.slug
              ">
                        <h3 class="ls-center">Pay with Stripe</h3>
                        <Stripe :order="order" @payment-result="handlePaymentResult"></Stripe>
                      </div>
                      <div class="ls-paymentmethod" v-if="paymentMethod.slug === 'contipay' &&
              selectedMethod === paymentMethod.slug
              ">
                        <h3 class="ls-center">Pay with ContiPay</h3>
                        <ContiPay :order="order" @payment-result="handlePaymentResult"></ContiPay>
                      </div>
                      <div class="ls-paymentmethod" v-if="paymentMethod.slug === 'voucher' &&
              selectedMethod === paymentMethod.slug
              ">
                        <h3 class="ls-center">Pay with Pahukama Voucher</h3>
                        <Voucher :order="order" @payment-result="handlePaymentResult"></Voucher>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center text-danger" v-else>
                    <div class="col-12 px-auto">
                      No payment options available
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-xl-5 col-lg-4 col-md-12 col-sm-12">
                <div class="ps-form__total">
                  <div class="content">
                    <div class="ps-block--checkout-total">
                      <div class="ps-block__content">
                        <table class="table ps-block__products" v-if="order && order.order_items">
                          <tbody v-if="order.type.name !== 'prescription'">
                            <tr v-for="orderItem in order.order_items" :key="orderItem.id">
                              <td>
                                <a href="#">{{ orderItem.name }}
                                  ×
                                  {{ orderItem.quantity }}</a>
                                <br />
                                Sold By:
                                <strong>{{ order.shop.name }}</strong>
                              </td>
                              <td>
                                {{ orderItem.total | toCurrency }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">&nbsp;&nbsp;</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>Subtotal</td>
                              <td class="text-right h3">
                                {{ order.total_amount | toCurrency }}
                              </td>
                            </tr>
                            <tr>
                              <td>Transport</td>
                              <td class="text-right h3">
                                {{ "0" | toCurrency }}
                              </td>
                            </tr>
                            <tr>
                              <td>Total</td>
                              <td class="text-right h3">
                                {{ order.total_amount | toCurrency }}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/mixins/global.js";
import { mapState } from "vuex";
import PaymentOptionsTab from "../../PaymentMethod/PaymentOptionsTab";
import PayNow from "../../PaymentMethod/PayNow";
import PayPal from "../../PaymentMethod/PayPal";
import MasterCard from "../../PaymentMethod/MasterCard";
import Stripe from "../../PaymentMethod/Stripe";
import ContiPay from "../../PaymentMethod/ContiPay";
import Voucher from "../../PaymentMethod/Voucher";

export default {
  components: {
    Stripe,
    MasterCard,
    PayPal,
    PayNow,
    PaymentOptionsTab,
    ContiPay,
    Voucher,
  },
  data() {
    return {
      cartTotal: 0,
      totalCartItems: 0,
      waiting: false,
      buttonText: "Waiting...",
      isPaid: false,
      countDown: 30,
      success_message: "",
      order: null,
      email: "",
      phone_number: "",
      token: "",
      braintreeIsReady: "",
      dropIninstance: "any",
      loadingPayment: true,
      paymentMethod: "",
      selectedMethod: null,
      message: "",
      paymentMethods: null,
      isLoading: false,
      paypalPo: true,
      contipayPo: true,
      stripePo: true,
    };
  },
  mounted() {
    this.getOrder();
    this.getPaymentMethods();
  },
  mixins: [global],
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {
    getOrder() {
      this.isLoading = true;
      this.$loading(true);
      axios
        .get(`orders/${this.$route.params.id}`)
        .then((response) => {
          this.order = response.data;
          this.validPaymentMethods();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getPaymentMethods() {
      axios.get("payment-methods?active=1").then((response) => {
        let paymentMethods = response.data;
        let firstItem = paymentMethods.splice(0, 1)[0];
        paymentMethods.splice(1, 0, firstItem);
        this.paymentMethods = paymentMethods;

        this.$loading(false);
        if (this.paymentMethods.length > 0) {
          this.selectedMethod = this.paymentMethods[0].slug;
        }
        this.validPaymentMethods();
      });
    },


    //Hide invalid payment methods. Check if order has alcohol or medicine
    validPaymentMethods() {
      //Check if the data has been downloaded
      if (!this.order || !this.paymentMethods) return;

      let shopsWithOrder = [];
      let shopsNoDupes = [];
      this.order.order_items.forEach((item) => {
        shopsWithOrder.push(item.shop.slug);
      });
      shopsNoDupes = [...new Set(shopsWithOrder)];
      //seach in shops list and if there are invalid shops, remove the not allowed payment methods

      let tempPmethods = [];
      this.paymentMethods.forEach((paymentMethod) => {
        //Set up an if statement to check valid payment methods
        shopsNoDupes.forEach((shop) => {
          let valid = true;
          switch (shop) {
            case "greenwood-pharmacy-online":
            case "pahukama-liquor":
              if (paymentMethod.slug == "stripe") valid = false;
              if (paymentMethod.slug == "paypal") valid = false;
              break;
            case "Greenwood":
              valid = false;
              break;
          }
          if (valid) tempPmethods.push(paymentMethod);
        });
      });

      this.paymentMethods.length = 0;
      this.paymentMethods.push(...tempPmethods);
      if (this.paymentMethods.length > 0) {
        this.selectedMethod = this.paymentMethods[0].slug;
      }
    },

    changeSelectedMethod(payload) {
      this.selectedMethod = payload["selectedMethod"];
      //alert(this.selectedMethod + " was Clicked.");
    },

    handlePaymentResult(payload) {
      console.log("payload", payload);
      this.isPaid = payload;
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }

      if (this.countDown === 0) {
        this.buttonText = "Checking Payment...";
        const dataToPost = { order_id: this.$route.params.id };
        axios.post("checkout/paynow/confirm", dataToPost).then((response) => {
          if (response.data.success === false) {
            this.success_message =
              "Payment Status can not be changed because payment have not been confirmed";
          }
          this.isLoading = false;
          this.isPaid = true;
        });
      }
    },

    viewOrder() {
      location.href = `/orders/${this.$route.params.id}`;
    },
  },
};
</script>

<style scoped>
.ps-block--payment {
  margin-top: 50px;
}

.ps-block__content {
  padding: 30px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 2px solid #eaeaea;
}

.container-fluid {
  background-color: #c5cae9;
}

.heading {
  font-size: 40px;
  margin-top: 35px;
  margin-bottom: 30px;
  padding-left: 20px;
}

.card {
  border-radius: 10px !important;
  margin-top: 60px;
  margin-bottom: 60px;
}

.form-card {
  margin-left: 20px;
  margin-right: 20px;
}

.form-card input,
.form-card textarea {
  padding: 10px 15px 5px 15px;
  border: none;
  border: 1px solid lightgrey;
  border-radius: 6px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  font-family: arial;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

.form-card input:focus,
.form-card textarea:focus {
  -moz-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  -webkit-box-shadow: 0px 0px 0px 1.5px skyblue !important;
  box-shadow: 0px 0px 0px 1.5px skyblue !important;
  font-weight: bold;
  border: 1px solid #304ffe;
  outline-width: 0;
}

.input-group {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.input-group input {
  position: relative;
  height: 80px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 6px;
  padding-top: 30px;
  padding-left: 25px;
}

.input-group label {
  position: absolute;
  height: 24px;
  background: none;
  border-radius: 6px;
  line-height: 48px;
  font-size: 15px;
  color: gray;
  width: 100%;
  font-weight: 100;
  padding-left: 25px;
}

input:focus+label {
  color: #304ffe;
}

.btn-pay {
  background-color: #304ffe;
  height: 60px;
  color: #ffffff !important;
  font-weight: bold;
}

.btn-pay:hover {
  background-color: #3f51b5;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

img {
  border-radius: 5px;
}

.radio-group {
  position: relative;
  margin-bottom: 25px;
}

.radio {
  display: inline-block;
  border-radius: 6px;
  box-sizing: border-box;
  border: 2px solid lightgrey;
  cursor: pointer;
  margin: 12px 25px 12px 0px;
}

.radio:hover {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
}

.radio.selected {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 155, 0.4);
  border: 2px solid blue;
}

.label-radio {
  font-weight: bold;
  color: #000000;
}
</style>
