<template>
  <div>
    <div class="ps-block--payment">
      <div v-if="isPaid" class="container">
        <div class="ps-block--payment-success">
          <h3>{{ success_message }} !</h3>
          <div v-if="message" class="alert alert-secondary" role="alert">
            {{ message }}
          </div>
          <p>
            Thank your for paying for your order. Please visit
            <router-link :to="{
              name: 'my_order',
              params: { id: $route.params.id },
            }">here</router-link>
            to check your order details.
          </p>
        </div>
      </div>
      </div>
    <div id="paypal-button"></div>
  </div>
</template>
<script>
import { loadScript } from "@paypal/paypal-js";
import axios from "axios";

export default {
  name: "PayPal",
  props: {
    order: Object
  },
  data() {
    return {
      account: 'sb-vyzfm8457694@business.example.com',
      'client_id': process.env.VUE_APP_PAYPAL_CLIENT_ID
    }
  },
  async mounted() {
    const order = this.order;
    const paypalSdk = await loadScript({
      'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID,
      currency: 'USD',
    });
    await paypalSdk.Buttons({
      style: {
        layout: 'vertical',
        color: 'blue',
        shape: 'rect',
        label: 'paypal'
      },
      createOrder: function (data, actions) {
        // Set up the transaction
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: order.total_amount
            }
          }]
        });
      },
      onApprove: function (data, actions) {
        return actions.order.capture()
          .then(async (details) => {
            try {
              const response = await axios.post(`paypal/${order.id}`, details);
              this.isLoading = false;
              this.paid = true;
              this.$store.dispatch("cart/clearCart");
              this.$toasted.success("Payment successfully completed");
              this.$emit("payment-result", true);
              this.$router.push({ name: 'my_orders' });
              console.log("here", response);
            } catch (error) {
              if (error) {
                if (error.response && error.response.status === 422) {
                  if (error.response.data.message) {
                    this.errors.message = error.response.data.message;
                  } else {
                    this.errors.message = "Payment could not be completed, please try again.";
                  }
                } else if (error.response && error.response.status === 401) {
                  this.errors.message = error.response.data.message;
                } else if (error.backendErrors) {
                  this.errors.message = error.backendErrors[0]
                }
              } else {
                this.errors.message = "Unexpected error occurred.";
              }
              this.isError = true
              this.isLoading = false;
            };
          });
      },
      onCancel: function (data) {
        console.log('Cancelled');
        console.log(data);
        // Show a cancel page, or return to cart
      },
      onError: function (err) {
        // For example, redirect to a specific error page
        console.log('onError');
        console.log(err);
      }
    }).render('#paypal-button');
  }
}
</script>

<style scoped></style>